.imageContainer {
  position: relative;
  display: flex;
}

.text {
  color: white;
  font-size: 24px;
  text-align: center;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px 15px;
}

.dividerLine {
  height: 50px;
}

.textDescription {
  color: white;
  font-size: 24px;
  text-align: center;
  background-color: rgba(0, 2, 11, 0.7);
  padding: 5px 15px;
}

.textContainerImage {
  position: absolute;
  left: 0;
  top: 3%;
  padding: 10px;
  color: #fff;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.searchBarContainer {
  z-index: 0;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
}

.responsiveImage {
  width: 100%; /*Set image width to fill the container*/
  height: 70vh; /* Set image height to fill the container */
  object-fit: cover; /* Scale the image while preserving aspect ratio */
  object-position: center center;
}

.textContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.subtitle {
  font-weight: 600;
}

.lowerPart {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.textRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.totalAmountText {
  text-align: right;
  margin-bottom: 20px;
  font-weight: 500;
}

.textAmountTitle {
  font-weight: 500;
  text-align: center;
}

.bookingDetails {
  display: flex;
  width: 100%;
  min-width: 275px;
  padding: 20px;
  justify-content: center;
}

@media screen and (min-width: 600px) {
  .lowerPart {
    flex-direction: row;
  }
}

@media screen and (max-width: 500px) {
  .textContainerImage {
    flex-direction: column;
    top: 0;
  }
  .searchBarContainer {
    top: 50%;
  }
}
@media screen and (max-width: 375px) {
  .searchBarContainer {
    top: 58%;
  }

  .text {
    padding: 0 10px;
  }
  .imageContainer {
    padding: 200;
    width: 100%;
  }
}
