.imageContainer {
  display: none;
}

.title {
  position: absolute;
  top: 28%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Jost", sans-serif;
  font-size: 45px;
  color: black; /* white text */
  border-radius: 100px;
  padding: 5px 30px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.lowerText {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Jost", sans-serif;
  font-size: 30px;
  color: white;
  text-align: center;
  font-weight: "700";
}

.mainContainer {
  position: "relative";
  display: "inline-block";
  width: 100%;
  justify-content: center;
  align-items: center;
}

.imageDesktopContainer {
  width: 100vw; /* 100% of the viewport's width */
  height: 91vh; /* 100% of the viewport's height */
  object-fit: cover; /* This will cover the whole area without distorting the aspect ratio */
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .imageContainer {
    display: block;
  }
  .mainContainer {
    height: "50%";
  }

  .title {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Jost", sans-serif;
    font-size: 35px;
  }

  .lowerText {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Jost", sans-serif;
    font-size: 25px;
    color: white;
    text-align: center;
    font-weight: "700";
  }
}
