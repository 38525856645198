.container {
  padding: 20px;
  justify-content: center;
  height: 80vh;
}

.formContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.bookingContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.upperPart {
  display: flex;
  flex-direction: column;
}

.formElement {
  margin-top: 20px;
  width: 300px;
}

.textRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.totalAmountText {
  text-align: right;
  margin-bottom: 10px;
}

.textAmountTitle {
  text-align: center;
  margin-bottom: 10px;
}

.bookingDetails {
  display: flex;
  width: 100%;
  width: 450px;
  padding: 20px;
  justify-content: center;
  background-color: rgb(216, 234, 252);
}

.emailMatchError {
  color: rgb(208, 5, 5);
  font-size: 16px;
  font-weight: 600;
}

@media screen and (max-width: 790px) {
  .formContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
  }
  .container {
    padding: 20px;
    justify-content: center;
    height: 100%;
  }

  .bookingDetails {
    display: flex;
    width: 100%;
    min-width: 400px;
    padding: 20px;
    justify-content: center;
    background-color: rgb(216, 234, 252);
  }
}

@media screen and (max-width: 400px) {
  .bookingDetails {
    min-width: 350px;
  }
}

@media screen and (max-width: 350px) {
  .bookingDetails {
    min-width: 300px;
  }
}
