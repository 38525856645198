.mainContainer {
  height: 80vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 100px;
  justify-content: center;
  align-items: center;
}

.img {
  width: 100px;
  height: 100px;
}

.text {
  font-size: 18px;
  font-weight: 500;
}

.upperContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.buttonContainer {
  width: 300px;
  align-items: center;
}

.textTitle {
  text-align: center;
}

@media (min-width: 1450px) {
  .mainContainer {
    height: 88vh;
  }
}

@media (min-height: 820px) {
  .mainContainer {
    height: 85vh;
  }
}
@media (min-height: 1200px) {
  .mainContainer {
    height: 87vh;
  }
}

@media (max-width: 376px) {
  .mainContainer {
    gap: 50px;
    height: 70vh;
  }
}
