.container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
}

.loaderContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main {
  position: absolute;
  width: 95%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
  padding: 20px;
  border-radius: 5px;
}

.text {
  color: white;
}

.controller {
  padding: 5px 50px;
  border-radius: 4px;
  min-width: 275px;
}

.productDetailsContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  gap: 20px;
}

@media (min-width: 1240px) {
  .buttonController {
    padding: 5px 50px;
    border-radius: 4px;
    min-width: 275px;
  }
}

@media (max-width: 1140px) {
  .container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
    gap: 20px;
  }
  .controller {
    padding: 5px 50px;
    border-radius: 4px;
    min-width: 275px;
  }
  .buttonController {
    padding: 5px 50px;
    border-radius: 4px;
    min-width: 275px;
  }
}
