.container {
  background-color: #0d6efd;
  padding: 20px;
}

.subContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.text {
  color: white;
}
